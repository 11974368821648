import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'

import React, { useEffect, useState } from 'react';
import { DetailsPanelProvider } from './DetailsPanelContext';
import DetailsPanel from './DetailsPanel';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from '@aws-amplify/auth'; // Correctly import the required function

import { Hub, I18n } from '@aws-amplify/core';
import { Alert, Authenticator } from '@aws-amplify/ui-react';
import { useAuthenticator, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Header from './Header/Header';
import Spinner from 'react-bootstrap/Spinner';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
  Navigate
} from 'react-router-dom';
import CustomerViewer from './Customers/CustomerView';
import { HomeScreen } from './Home';
import { AuthPortal } from './AuthenticationScreen/PortalSelector';
import InstallationPrompt from './PWA/InstallationPrompt';
import { AuthGetRequest } from './Backend/APIMiddleware';
import { CustomerDetails } from './Customers/ViewCustomerPage';
import { FaSpinner } from 'react-icons/fa';
import { PublicHomePageEditor } from './PublicPagesModules/PublicPagePreviewer';
import { useTranslation } from 'react-i18next';
import TicketCreation from './TicketComponents/CreateTicket';
import { TransactionDetails } from './TicketComponents/TransactionDetails';

// Add your translations for Spanish
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('en', {
  'Create Account': 'Register',
  'Family Name': 'Last Name',
  'Given Name': 'First Name',
  'Enter your Family Name': 'Enter your last name',
  'Enter your Given Name': 'Enter your first name'
});
I18n.putVocabulariesForLanguage('es', {
  'Create Account': 'Crear Cuenta',
  'Family Name': 'Apellido',
  'Given Name': 'Nombre',
  'Enter your Family Name': 'Ingrese su apellido',
  'Enter your Given Name': 'Ingrese su nombre',
  'Enter your Phone Number': 'Ingrese su número telefónico',
  'Phone Number': 'Número Telefónico',
  'Sign In': 'Iniciar Sesión',
  'Password': 'Contraseña'
});

Amplify.configure(awsExports);

export default function App() {
  const { t } = useTranslation();

  //Handle Logout section:

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        // Perform any cleanup or navigation on sign out
        //Redirect to logout page
        window.location.href = '/?session=signed-out';
      }
    });
  }, []);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const userData = await getCurrentUser();
        if (userData) {
          // console.log('User is signed in', userData);
          setUser(userData);
        } else {
          console.log('No user is signed in');
          setUser(null);
        }
      } catch (error) {
        console.log('Error fetching user:', error);
        setUser(null);
      }
    };

    // Set up the listener
    const unsubscribe = Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn' || data.payload.event === 'signOut') {
        checkCurrentUser();
      }
    });

    // Initial check
    checkCurrentUser();

    // Unsubscribe on unmount
    return () => unsubscribe();
  }, []);

  const { route } = useAuthenticator(context => [context.route]);
  const [navOpen, setNavOpen] = useState(false);
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(true);
  const [userRole, setUserRole] = useState({ isSet: false });
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };
  function verifyDrawerOpen() {
    if (navOpen) {
      toggleNav();
    }
  }
  //PWA Checker for mobile:
  const [isIOS, setIsIOS] = useState(false);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);

  useEffect(() => {
    // Detect iOS devices
    const userAgent = window.navigator.userAgent.toLowerCase();
    const appleDevice = /iphone|ipad|ipod/.test(userAgent);
    setIsIOS(appleDevice);

    // Check if running in standalone mode on iOS
    if (appleDevice) {
      const isStandalone = window.navigator.standalone;
      setIsPWAInstalled(isStandalone);
    }
  }, []);

  const [roleErrorMSG, setRoleErrMsg] = useState(``)
  useEffect(() => {
    if (route === 'authenticated' && userRole.isSet === false) {
      AuthGetRequest(`/v1/accounts/my-role`).then((response) => {
        setUserRole(response.data)
      }).catch((error) => {
        console.error(error.response.data)
        setRoleErrMsg(error.response.data)
      })
    }
  }, [user, route, userRole])

  return (
    <DetailsPanelProvider>
      {route === 'setup' && (<Authenticator />)}
      <div className="app-container">
        <Router>

          {route !== 'authenticated' && (
            <>
              <Header toggleNav={toggleNav} />
              <div className="main-content" onClick={verifyDrawerOpen}>
                <Routes>
                  <Route path='/' element={<HomeScreen />} />
                  <Route path='*' element={<>
                    {isIOS && isPWAInstalled && (<>
                      <div className='centered'>
                        <img src="/logo.jpg" alt="Comapny Logo" style={{ height: 'auto', width: '100px', borderRadius:'50%' }} />
                        
                      </div>
                      <h1 className='centered'>{t('Login')}</h1>

                      <br/>
                      <Authenticator hideSignUp />
                    </>)}
                    {isIOS && !isPWAInstalled && (<InstallationPrompt />)}
                    {!isIOS && isPWAInstalled && (<>
                      <div className='centered'>
                        <img src="/logo.jpg" alt="Comapny Logo" style={{ height: 'auto', width: '100px', borderRadius:'50%' }} />
                        
                      </div>
                      <h1 className='centered'>{t('Login')}</h1>

                      <br/>
                      <Authenticator hideSignUp />
                    </>)}
                    {!isIOS && !isPWAInstalled && (<>
                      <div className='centered'>
                        <img src="/logo.jpg" alt="Comapny Logo" style={{ height: 'auto', width: '100px', borderRadius:'50%' }} />
                        
                      </div>
                      <h1 className='centered'>{t('Login')}</h1>

                      <br/>
                      <Authenticator hideSignUp />
                    </>)}

                  </>} />

                </Routes>
              </div>
              <br />
            </>
          )}
          {route === 'authenticated' && (
            <Authenticator hideSignUp>
              {({ signOut, user }) => (
                <>
                  <Header signOut={signOut} user={user} toggleNav={toggleNav} />
                  <div className='body-container'>
                    <div className={`left-navigation ${navOpen ? "open" : "close"}`}>
                      <h3>{t("Menu")}:</h3>
                      <div className="navigation-items">

                        <div><NavLink className='navigation-link' to={'/'} onClick={toggleNav}>{t("Home")}</NavLink></div>
                        {!userRole.isEmployee && !userRole.isCustomer && (<><div className='centered'>
                          {!roleErrorMSG && (<Spinner size='xlg' color='#0a2a52' />)}
                          {roleErrorMSG && (<Alert variation='error'>{roleErrorMSG}</Alert>)}
                        </div></>)}
                        {userRole.isEmployee && (
                          <>
                            <h4>{t("Tickets")}:</h4>
                            <div><NavLink className='navigation-link' to={'/ticket/create'} onClick={toggleNav}>{t("New Ticket")}</NavLink></div>
                            <h4>{t("Layaways")}:</h4>
                            <div><NavLink className='navigation-link' to={'/layaways'} onClick={toggleNav}>{t("View Layaways")}</NavLink></div>
                            <div><NavLink className='navigation-link' to={'/layaway/record'} onClick={toggleNav}>{t("Record Payment")}</NavLink></div>
                            <h4>{t("Repairs")}:</h4>
                            <div><NavLink className='navigation-link' to={'/repairs/queue'} onClick={toggleNav}>{t("Repair Queue")}</NavLink></div>
                            <div><NavLink className='navigation-link' to={'/repairs?status=Completed'} onClick={toggleNav}>{t("Completed Repairs")}</NavLink></div>
                            <h4>{t("Sale")}s:</h4>
                            <div><NavLink className='navigation-link' to={'/pos/sales'} onClick={toggleNav}>{t("Today's Sales")}</NavLink></div>
                            <h4>{t("Customers")}:</h4>
                            <div><NavLink className='navigation-link' to={'/customers/view'} onClick={toggleNav}>{t("View Customers")}</NavLink></div>
                            <h4>{t("Public Website")}:</h4>
                            <div><NavLink className='navigation-link' to={'/public-site?view=edit'} onClick={toggleNav}>{t("Edit Public Website")}</NavLink></div>
                          </>
                        )}
                        {userRole.isCustomer && (
                          <>
                            <h3>{t("My Account")}:</h3>
                            <div className="navigation-items">
                              {/* <div><NavLink className='navigation-link' to={'/my/layaways'} onClick={toggleNav}>{t("My")} {t("Layaways")}</NavLink></div>
                              <div><NavLink className='navigation-link' to={'/my/repairs'} onClick={toggleNav}>{t("My")} {t("Repairs")}</NavLink></div>
                              <div><NavLink className='navigation-link' to={'/my/purchases'} onClick={toggleNav}>{t("My")} {t("Purchases")}</NavLink></div> */}
                              <div><NavLink className='navigation-link' to={`/customers/view-customer?customerID=${user.userId}`} onClick={toggleNav}>{t("My Account")}</NavLink></div>
                              {/* <div><NavLink className='navigation-link' to={'/my/payment-transactions'} onClick={toggleNav}>{t("Recent Transactions")}</NavLink></div> */}

                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <DetailsPanel />
                    <div className="main-content" onClick={verifyDrawerOpen}>
                      <Routes>
                        <Route path='/' element={<HomeScreen />} />
                        <Route path='/login' element={<Navigate to="/" />} />
                        <Route path='/public-site' element={<PublicHomePageEditor />} />
                        <Route path='/customers/view-customer' element={<CustomerDetails currentUser={user} credentials={userRole} />} />
                        {userRole.isEmployee &&(
                          <>
                            <Route path='/customers/view' element={<CustomerViewer />} />
                            <Route path='/ticket/create' element={<TicketCreation />} />
                            <Route path="/transactions/:type/:transactionId" element={<TransactionDetails />} />


                          </>
                        )}
                      </Routes>
                    </div>
                  </div>
                </>
              )}
            </Authenticator>
          )}

          <Footer />
        </Router>
      </div>
    </DetailsPanelProvider>
  );
}
function Footer() {
  return (
    <footer>
      <div className='centered'><small><small>© 2024-2025 The Jewelry Doctors of Auburndale Inc</small></small> </div>
      {/* <div><small>Powered and designed by Org Tools & Solutions Inc</small></div> */}

    </footer>
  )
}
