import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Button, Card, Container, Row, Spinner, Col, Modal, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthGetRequest, AuthPostRequest } from '../Backend/APIMiddleware';
import { Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

// Define queryDocumentHistory within the file or import it if it's in a separate file
async function queryDocumentHistory(docType, docId, pageSize, lastItem) {
    try {
        const baseUrl = `/v1/document-history/${docType}/${docId}`;
        const queryParams = [`pageSize=${pageSize}`];
        
        if (lastItem !== null) {
            queryParams.push(`lastItem=${lastItem}`);
        }
        
        const url = `${baseUrl}?${queryParams.join('&')}`;
        
        const response = await AuthPostRequest(url);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching paged history:", error);
        throw error;
    }
}

export const CustomerDetails = (props) => {
    const { credentials, currentUser } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const customerID = queryParams.get('customerID');
    const [purchases, setPurchases] = useState([]);
    const [repairs, setRepairs] = useState([]);
    const [layaways, setLayaways] = useState([]);
    const [accountHistory, setAccountHistory] = useState([]);
    const [lastSizes, setLastSizes] = useState({ purchases: 0, repairs: 0, layaways: 0, accountHistory: 0 });
    const pageSize = 25;
    const [customerData, setCustomerData] = useState({});
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showEditEmailModal, setShowEditEmailModal] = useState(false);
    const [newCustomerEmail, setNewCustomerEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        const fetchInitialData = async () => {
            setIsLoadingData(true);
            const customerResponse = await AuthGetRequest(`/v1/accounts?customerID=${customerID}`);
            setCustomerData(customerResponse.data);
            await fetchMoreData('purchases');
            await fetchMoreData('repairs');
            // await fetchMoreData('layaways');
            await fetchMoreData('accountHistory');
            setIsLoadingData(false);
        };
        fetchInitialData();
    }, [customerID]);

    const fetchMoreData = async (type) => {
        let lastTransactionId = '';
        let currentData = [];

        try {
            if (type === 'accountHistory') {
                currentData = accountHistory;
                lastTransactionId = accountHistory.length ? accountHistory[accountHistory.length - 1].id : null; // Use null for first call
                const response = await queryDocumentHistory('account', customerID, pageSize, lastTransactionId);
                setLastSizes(prev => ({ ...prev, [type]: response.length }));
                if (response.length > 0) {
                    setAccountHistory([...accountHistory, ...response]);
                }
            } else {
                let apiEndpoint = '';
                switch (type) {
                    case 'purchases':
                        currentData = purchases;
                        lastTransactionId = purchases.length ? purchases[purchases.length - 1].transactionId : '';
                        apiEndpoint = `/v1/transactions/customer-search?customerId=${customerID}&ticketType=Sale&pageSize=${pageSize}&lastTransactionId=${lastTransactionId}`;
                        break;
                    case 'repairs':
                        currentData = repairs;
                        lastTransactionId = repairs.length ? repairs[repairs.length - 1].transactionId : '';
                        apiEndpoint = `/v1/transactions/customer-search?customerId=${customerID}&ticketType=Repair&pageSize=${pageSize}&lastTransactionId=${lastTransactionId}`;
                        break;
                    case 'layaways':
                        currentData = layaways;
                        lastTransactionId = layaways.length ? layaways[layaways.length - 1].transactionId : '';
                        apiEndpoint = `/v1/transactions/customer-search?customerId=${customerID}&ticketType=Layaway&pageSize=${pageSize}&lastTransactionId=${lastTransactionId}`;
                        break;
                    default:
                        return;
                }
                const response = await AuthGetRequest(apiEndpoint);
                console.log(`Data Length (${type}): ${response.data.length}`);
                console.log(`Is the same as pageSize? ${response.data.length === pageSize}`);
                setLastSizes(prev => ({ ...prev, [type]: response.data.length }));
                if (response.data.length > 0) {
                    switch (type) {
                        case 'purchases':
                            setPurchases([...purchases, ...response.data]);
                            break;
                        case 'repairs':
                            setRepairs([...repairs, ...response.data]);
                            break;
                        case 'layaways':
                            setLayaways([...layaways, ...response.data]);
                            break;
                    }
                }
            }
        } catch (error) {
            console.error(`Error fetching more ${type}:`, error);
        }
    };

    const handleResetPassword = async () => {
        setIsResettingPassword(true);
        try {
            await AuthPostRequest('/v1/accounts', { customerID });
            alert('Password reset successfully');
        } catch (error) {
            console.error('Error resetting password:', error);
        } finally {
            setIsResettingPassword(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEditEmail = async () => {
        if (!validateEmail(newCustomerEmail)) {
            setEmailError(t('Please enter a valid email address'));
            return;
        }
        setEmailError('');
        try {
            await AuthPostRequest(`/v1/accounts/update-email?customerID=${customerID}&email=${newCustomerEmail}`);
            setCustomerData({ ...customerData, email: newCustomerEmail });
            alert('Email updated successfully');
            setShowEditEmailModal(false);
        } catch (error) {
            console.error('Error updating email:', error);
        }
    };

    const convertToLocalTime = (utcDateString) => {
        if (!utcDateString) return t("N/A");
        const date = new Date(utcDateString + " UTC");
        if (isNaN(date.getTime())) return t("Invalid Date");
        return new Intl.DateTimeFormat(navigator.language, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    };

    const goToTransactionDetails = (transactionId, transactionType) => {
        const path = `/transactions/${transactionType}/${transactionId}`;
        navigate(path);
    };

    const renderCard = (item, type) => (
        <Card className="mb-3" key={item.transactionID || item.id}>
            <Card.Header>
                <Row>
                    <Col><strong>{t(type === 'accountHistory' ? 'Event' : 'Transaction #')}:</strong> <br />{type === 'accountHistory' ? item.logType : item.ticketId}</Col>
                    {type !== 'accountHistory' && <Col className="text-end"><strong>{t('Total')}:</strong> ${item.transactionTotal}</Col>}
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {type === 'accountHistory' ? (
                        <>
                            <strong>{t('Date')}:</strong> {convertToLocalTime(item.logDate)}<br />
                            <strong>{t('Employee')}:</strong> {item.employeeName || t('N/A')}<br />
                            <strong>{t('Details')}:</strong> {item.logMessage || t('N/A')}<br />
                        </>
                    ) : (
                        <>
                            <strong>{t('Transaction Date')}:</strong> {convertToLocalTime(item.transactionDate)}<br />
                            <strong>{t('Employee')}:</strong> {item.employeeName}<br />
                            <strong>{t('Total Items')}:</strong> {item.totalItems}<br />
                            <strong>{t('Balance Due')}:</strong> ${item.balanceDue}<br />
                            {type === 'layaway' && (
                                <>
                                    <strong>{t('Contract Start Date')}:</strong> {item.contractStartDate}<br />
                                    <strong>{t('Time Remaining to Pay')}:</strong> {item.timeRemainingToPay}<br />
                                </>
                            )}
                        </>
                    )}
                </Card.Text>
                {type !== 'accountHistory' && (
                    <Button variant="primary" disabled onClick={() => goToTransactionDetails(item.transactionId, type)}>{t('View Details')}</Button>
                )}
            </Card.Body>
            {type !== 'accountHistory' && (
                <Card.Footer>
                    <Row>
                        <Col><strong>{t('Status')}:</strong><br /> {item.transactionStatus || item.layawayStatus || 'Completed'}</Col>
                        <Col className="text-end"><strong>{t('Status Updated')}:</strong><br /> {convertToLocalTime(item.statusDate || item.paidFullDate || 'N/A')}</Col>
                    </Row>
                </Card.Footer>
            )}
        </Card>
    );

    return (
        <Container fluid>
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h4>{customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}</h4>
                        </Col>
                        {credentials.isEmployee && (
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => setShowModal(true)}>{t('Reset Password')}</Button>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <Card.Text>
                                <strong>{t('Account name')}:</strong> {customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}<br />
                                <strong>{t('Account Type')}:</strong> {(customerData.isBusiness ? (<>{t('Business')}</>) : (<>{t('Customer')}</>))}<br />
                                <strong>{t('Phone Number')}:</strong> {customerData.phone}<br />
                                <strong>{t('Email')}:</strong> {customerData.email || 'Not on File'}{' '}
                                {(credentials.isEmployee || customerID === currentUser.userId) && (
                                    <><FaEdit onClick={() => setShowEditEmailModal(true)} /></>
                                )}<br />
                                <Form.Check
                                    type="switch"
                                    label={<strong>{t('Email Promotions')}</strong>}
                                    disabled={(!customerData.emailPromotions && customerID !== currentUser.userId) || !customerData.email}
                                    checked={customerData.emailPromotions}
                                    onChange={() => { console.log("disabled") }}
                                />
                            </Card.Text>
                        </Col>
                        <Col md={4}>
                            <Card.Text>
                                <strong>{t('Total Spend')} {customerData.currentYear}:</strong> ${customerData.yearSpend}<br />
                                <strong>{t('Total Savings')} {customerData.currentYear}:</strong> ${customerData.yearSave}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Tabs defaultActiveKey="purchases" id="customer-details-tabs" className="mb-3">
                <Tab eventKey="purchases" title={t('Purchases')}>
                    {purchases.map((purchase) => renderCard(purchase, 'sale'))}
                    {lastSizes.purchases === pageSize && (
                        <Button variant="primary" onClick={() => fetchMoreData('purchases')}>{t('Load More')}</Button>
                    )}
                    {lastSizes.purchases !== pageSize && (
                        <>No more purchases</>
                    )}
                </Tab>
                <Tab eventKey="repairs" title={t('Repairs')}>
                    {repairs.map((repair) => renderCard(repair, 'repair'))}
                    {lastSizes.repairs === pageSize && (
                        <Button variant="primary" onClick={() => fetchMoreData('repairs')}>{t('Load More')}</Button>
                    )}
                    {lastSizes.repairs !== pageSize && (<>No more repairs</>)}
                </Tab>
                <Tab eventKey="layaways" title={t('Layaway Contracts')}>
                    {layaways.map((layaway) => renderCard(layaway, 'layaway'))}
                    {lastSizes.layaways === pageSize && (
                        <Button variant="primary" onClick={() => fetchMoreData('layaways')}>{t('Load More')}</Button>
                    )}
                    {lastSizes.layaways !== pageSize && (<>No more layaways</>)}
                </Tab>
                <Tab eventKey="accountHistory" title={t('Account History')}>
                    {accountHistory.map((history) => renderCard(history, 'accountHistory'))}
                    {lastSizes.accountHistory === pageSize && (
                        <Button variant="primary" onClick={() => fetchMoreData('accountHistory')}>{t('Load More')}</Button>
                    )}
                    {lastSizes.accountHistory !== pageSize && (<>No more history</>)}
                </Tab>
            </Tabs>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Reset Password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isResettingPassword ? (
                        <Spinner animation="border" />
                    ) : (
                        <p>{t('Are you sure you want to reset the password for this customer?')}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>{t('Cancel')}</Button>
                    <Button variant="primary" onClick={handleResetPassword} disabled={isResettingPassword}>
                        {isResettingPassword ? <Spinner as="span" animation="border" size="sm" /> : t('Reset Password')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditEmailModal} onHide={() => setShowEditEmailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Edit Email')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('Enter new email')}
                            value={newCustomerEmail}
                            onChange={(e) => setNewCustomerEmail(e.target.value)}
                            isInvalid={!!emailError}
                        />
                        <FormControl.Feedback type="invalid">
                            {emailError}
                        </FormControl.Feedback>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditEmailModal(false)}>{t('Cancel')}</Button>
                    <Button variant="primary" onClick={handleEditEmail}>{t('Submit')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isLoadingData} centered>
                <Modal.Body>
                    <div className='centered'>
                        <h3>{t("Getting Account Information")}</h3>
                    </div>
                    <div className='centered'><Spinner size='xl' /></div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};