
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button, Modal, Form, Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthGetRequest, AuthPostRequest } from '../Backend/APIMiddleware';

// Add the new queryDocumentHistory function within the same file or import it
export async function queryDocumentHistory(docType, docId, pageSize, lastItem) {
    try {
        const response = await AuthPostRequest(`/v1/document-history/${docType}/${docId}?pageSize=${pageSize}&lastItem=${lastItem}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching paged history:", error);
        throw error;
    }
}

export const TransactionDetails = () => {
    const { t } = useTranslation();
    const { type, transactionId } = useParams();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [payments, setPayments] = useState([]);
    const [ticketHistory, setTicketHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [layawayOption, setLayawayOption] = useState(false);
    const [lastHistoryItem, setLastHistoryItem] = useState(null); // Track last item for pagination
    const [hasMoreHistory, setHasMoreHistory] = useState(true); // Track if more history available

    const PAGE_SIZE = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchTransactionDetails().then(() => 
                    fetchCustomerDetails()
                );
                await fetchLineItems();
                await fetchPayments();
                await fetchInitialTicketHistory();
            } catch (error) {
                console.error('Error fetching transaction data:', error);
            }
        };

        fetchData();
    }, [type, transactionId]);

// Fetch Customer Details
const fetchCustomerDetails = async () => {
    try {
        const response = await AuthGetRequest(`/v1/accounts?customerID=${transaction.customerId}`); // Assuming transactionId can be used to fetch customer data
        setCustomer(response.data);
    } catch (error) {
        console.error('Error fetching customer details:', error);
    }
};

// Fetch Transaction Details
const fetchTransactionDetails = async () => {
    try {
        const response = await AuthGetRequest(`/v1/transactions/${type}/${transactionId}`);
        setTransaction(response.data);
    } catch (error) {
        console.error('Error fetching transaction details:', error);
    }
};

// Fetch Line Items
const fetchLineItems = async () => {
    try {
        const response = await AuthGetRequest(`/v1/transactions/${type}/${transactionId}/line-items`);
        setLineItems(response.data);
    } catch (error) {
        console.error('Error fetching line items:', error);
        setLineItems([]); // Set empty array if no line items found
    }
};

// Fetch Payments
const fetchPayments = async () => {
    try {
        const response = await AuthGetRequest(`/v1/transactions/${type}/${transactionId}/payments`);
        setPayments(response.data || []);
    } catch (error) {
        console.error('Error fetching payments:', error);
        setPayments([]); // Set empty array if no payments found
    }
};
    // Fetch initial ticket history
    const fetchInitialTicketHistory = async () => {
        try {
            const data = await queryDocumentHistory(type, transactionId, PAGE_SIZE, null);
            setTicketHistory(data || []);
            setLastHistoryItem(data && data.length > 0 ? data[data.length - 1].id : null);
            setHasMoreHistory(data && data.length === PAGE_SIZE);
        } catch (error) {
            console.error('Error fetching ticket history:', error);
            setTicketHistory([]);
            setHasMoreHistory(false);
        }
    };

    // Fetch more ticket history
    const fetchMoreTicketHistory = async () => {
        try {
            const data = await queryDocumentHistory(type, transactionId, PAGE_SIZE, lastHistoryItem);
            setTicketHistory(prev => [...prev, ...(data || [])]);
            setLastHistoryItem(data && data.length > 0 ? data[data.length - 1].id : lastHistoryItem);
            setHasMoreHistory(data && data.length === PAGE_SIZE);
        } catch (error) {
            console.error('Error fetching more ticket history:', error);
            setHasMoreHistory(false);
        }
    };

    // ... (keep handleSave, handleLayawayToggle, handleVoid the same)

    if (!customer || !transaction || !lineItems || !payments || !ticketHistory) {
        return <Modal show={true} centered>
        <Modal.Body>
            <div className='centered'>
                <h3>{t("Getting Transaction Details")}</h3>
            </div>
            <div className='centered'><Spinner size='xl' /></div>
        </Modal.Body>
    </Modal>;
    }

    const customerName = customer.isBusiness ? customer.firstName : `${customer.firstName} ${customer.lastName}`.trim();

    return (
        <Container className="mt-4">
            <Card>
                {/* ... (keep Card.Header and most of Card.Body the same) */}
                <Card.Body>
                    {/* ... (keep existing content up to ticket history button) */}

                    {/* View Ticket History Button and Modal */}
                    <Button variant="secondary" className="mt-3" onClick={() => setShowHistory(true)}>
                        {t('View Ticket History')}
                    </Button>

                    <Modal show={showHistory} onHide={() => setShowHistory(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('Ticket History')} - {transaction.ticketId || t('N/A')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                        <th>{t('User')}</th>
                                        <th>{t('Details')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketHistory.map(history => (
                                        <tr key={history.id}>
                                            <td>{new Intl.DateTimeFormat(navigator.language, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            }).format(new Date(history.date))}</td>
                                            <td>{history.action || t('N/A')}</td>
                                            <td>{history.user || t('N/A')}</td>
                                            <td>{history.details || t('N/A')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {hasMoreHistory && (
                                <Button 
                                    variant="primary" 
                                    onClick={fetchMoreTicketHistory}
                                    className="mt-2"
                                >
                                    {t('Load More')}
                                </Button>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowHistory(false)}>
                                {t('Close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* ... (keep Void Button section) */}
                </Card.Body>
            </Card>
        </Container>
    );
};